<template>
  <v-menu v-if="mensajesSinLeer > 0" offset-y zIndex="999">
    <template v-slot:activator="{ on, attrs }">
      <v-badge overlap :content="mensajesSinLeer" color="error darken-1">
        <v-btn icon v-bind="attrs" v-on="on" text>
          <v-icon>mdi-message-alert-outline</v-icon>
        </v-btn>
      </v-badge>
    </template>

    <v-card class="pa-2 d-flex flex-column">
      <v-btn @click="getMensajesSinLeer" color="primary" text>
        <v-icon left>mdi-refresh</v-icon>
        recargar</v-btn
      >
      <v-btn @click="markAllAsRead" color="primary" text>
        <v-icon left>mdi-message-bookmark-outline</v-icon>
        Marcar todos como leidos</v-btn
      >
    </v-card>
  </v-menu>
</template>

<script>
export default {
  data() {
    return {
      mensajesSinLeer: 0,
    };
  },
  mounted() {
    this.getMensajesSinLeer();
  },
  methods: {
    async getMensajesSinLeer() {
      let { data: msg } = await axios({
        method: "GET",
        url: `${process.env.VUE_APP_API_URL}/leerMensajes.php`,
      });

      this.mensajesSinLeer = Number(msg);
    },
    async markAllAsRead() {
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_API_URL}/leerMensajes.php`,
        data: { all: 1 },
      });

      this.$emit('reload')
      this.getMensajesSinLeer();
    },
  },
};
</script>
